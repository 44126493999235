// import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import NavBar from './core/NavBar';
import Footer from './core/Footer';
import ContentRouter from './core/ContentRouter';

const App = (props: any) => {
  return (
    <Main>
      {/*<NavBar />*/}
      <ContentRouter />
      {/*<Footer />*/}
    </Main>
  );
}

export default withRouter(App);

const Main = styled.div`
  height: 100%;
`;