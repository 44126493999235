// import React, { useEffect, useRef, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import Standings from '../views/Standings';
import Welcome from '../views/Welcome';

const ContentRouter = (props: any) => {
    const { location } = props;
    return (
        <Main>
            <Msg>
               Currently not in service
            </Msg>
            {/*<Content>*/}
            {/*    <Switch location={location}>*/}
            {/*        <Route exact path="/" render={() => <Welcome />} />*/}
            {/*        <Route exact path="/standings/formula1/" render={() => <Standings />} />*/}
            {/*    </Switch>*/}
            {/*</Content>*/}
        </Main>
    );
};

export default ContentRouter;

const Main = styled.div`
    width: 100%;    
    min-height: 100%;
    margin-bottom: -200px;
    background: skyblue;
    background: rgb(151, 40, 178) !important;
    background-repeat: no-repeat;
    min-height: 100%;
    display: flex;
    @media only screen and (min-width: 1201px) {
        background: linear-gradient(
            125deg,
            rgba(124, 44, 225, 1) 0%,
            rgba(151, 40, 178, 1) 50%,
            rgba(129, 43, 217, 1) 100%
        ) !important;
    }
`;

const Content = styled.div`
    margin: 0 auto;
    width: 100%;
    max-width: 1200px !important;
    padding-bottom: 200px;
    padding-top: 40px;
`;

const Msg = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-style: italic;
`;